import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Section1 from '../components/sections/section1'
import Section16 from '../components/sections/section16'
import CommonFooter from '../components/footer/commonFooter'
import SiteNavigation from '../components/header/siteNavigation'
import HighlightsSeries from '../components/common/highlightsSeries'
import Seo from '../components/seo/seo'
import { useTranslation } from 'react-i18next'
import ProductHeader from '../components/header/productHeader'

const About = () => {
  const { t } = useTranslation();
  return (
  <StaticQuery
    query={graphql`
      query {
        team: file(relativePath: { eq: "about/team.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 900, quality: 90) {
              src
            }
          }
        }
        sensoristica: file(relativePath: { eq: "icons/Sensoristica.png" }) {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        schedapotenza: file(relativePath: { eq: "icons/SchedaIO.png" }) {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hera: file(relativePath: { eq: "icons/HERA.png" }) {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        el6: file(relativePath: { eq: "icons/EL6.png" }) {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        sviluppoSoftware: file(
          relativePath: { eq: "icons/SviluppoSoftware.png" }
        ) {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        progettazioneElettronica: file(
          relativePath: { eq: "icons/ProgettazioneElettronica.png" }
        ) {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        contractElectronicsManufacturing: file(
          relativePath: { eq: "icons/ContractElectronicsManufacturing.png" }
        ) {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Seo
          title={t('about.seoTitle')}
          description={t('about.seoDescription')}
          seoImage={data.team.childImageSharp.resize.src}
        />
        <SiteNavigation />
        <ProductHeader
          imageOpacity="0.9"
          headerTitle={t('about.headerTitle')}
          headerSubtitle={t('about.headerSubtitle')}
          fluid={data.team.childImageSharp.fluid}
        />
        <Section1
          isGradient="maingrad"
          titleColor="text-white"
          section1Title={t('about.title')}
          section1Text={t('about.description')}
          alt={t('about.altSection1Image')}
        />
        <HighlightsSeries
          title={t('about.hsTitle')}
          mainOne={t('about.value1')}
          descriptionOne={t('about.value1desc')}
          mainTwo={t('about.value2')}
          descriptionTwo={t('about.value2desc')}
          mainThree={t('about.value3')}
          descriptionThree={t('about.value3desc')}
        />
        <Section16
          gradient="maingrad"
          textColor="text-white"
          sectionTitle={t('about.discoverTitle')}
          sectionDesc={t('about.discoverDesc')}
          features={[1, 2, 3, 4, 5, 6]}
          featureTitle={[
            <>{t('about.feature1')}</>,
            <>{t('about.feature2')}</>,
            <>{t('about.feature3')}</>,
            <>{t('about.feature4')}</>,
            <>{t('about.feature5')}</>,
            <>{t('about.feature6')}</>,
          ]}
          featureDesc={[
            <>{t('about.feature1desc')}</>,
            <>{t('about.feature2desc')}</>,
            <>{t('about.feature3desc')}</>,
            <>{t('about.feature4desc')}</>,
            <>{t('about.feature5desc')}</>,
            <>{t('about.feature6desc')}</>,
          ]}
          featureButton={[
            <>{t('about.featureButton')}</>,
            <>{t('about.featureButton')}</>,
            <>{t('about.featureButton')}</>,
            <>{t('about.featureButton')}</>,
            <>{t('about.featureButton')}</>,
            <>{t('about.featureButton')}</>,
          ]}
          featureButtonLink={[
            '/prodotti/hera-laundry',
            '/prodotti/fiber-el6',
            '/prodotti/schede-rele-interfaccia-plc',
            '/prodotti/sonde-di-temperatura',
            '/servizi/progettazione-elettronica',
            '/servizi/produzione-schede-elettroniche',
          ]}
          imageCss={{ top: 0, left: 0, right: 0, bottom: 0 }}
          imageStyle={{
            position: `relative`,
            width: '180px',
            height: '180px',
          }}
          imageFixed={[
            data.hera.childImageSharp.fixed,
            data.el6.childImageSharp.fixed,
            data.schedapotenza.childImageSharp.fixed,
            data.sensoristica.childImageSharp.fixed,
            data.progettazioneElettronica.childImageSharp.fixed,
            data.contractElectronicsManufacturing.childImageSharp.fixed,
          ]}
          alt={[
            t('about.altHeraIcon'),
            t('about.altEL6Icon'),
            t('about.altSchedaPotenzaIcon'),
            t('about.altSensoriIcon'),
            t('about.altProgettazioneIcon'),
            t('about.altProduzioneIcon'),
          ]}
        />
        <CommonFooter />
      </>
    )}
  />
  )
}

export default About
